import React, { useRef } from 'react'
import Slider from 'react-slick'

import { useApp } from '@/core/contexts/app'
import { useWidth } from '@/core/hooks/useWidthResize'

import { Column, Container, Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor/Anchor'
import Icon from '@/components/base/icon/Icon'
import Image from '@/components/base/image/Image'

import { NewsProps } from './type'
import { useMobile } from '@/core/hooks'

const News: React.FunctionComponent<NewsProps> = ({ title, data }) => {
  const app = useApp()
  const width = useWidth()
  const isMobile = useMobile(app.headers['user-agent'])
  const slider = useRef<Slider>(null)

  const HTMLLimit: React.FC<{ content: string; maxLength: number }> = ({
    content,
    maxLength,
  }) => {
    if (!content) {
      return null
    }

    if (content.length <= maxLength) {
      return <div dangerouslySetInnerHTML={{ __html: content }} />
    }

    const lastSpaceIndex = content.lastIndexOf(' ', maxLength)
    const truncatedContent = content.substring(0, lastSpaceIndex) + '...'

    return <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />
  }

  const renderSlider = () => {
    const settings = {
      dots: false,
      infinite: data && data.length > 3,
      // slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      speed: 500,
      autoplaySpeed: 4000,
      cssEase: 'linear',
      arrows: false,
      slidesToShow: isMobile ? 1 : 3,
    }

    return (
      <div className="news-slide">
        {data && data.length > 3 && (
          <button
            className="news-slide-arrow news-slide-arrow--prev"
            onClick={() => slider.current?.slickPrev()}
          >
            <Icon name="arrow-back" />
          </button>
        )}
        <Slider {...settings} ref={slider}>
          {data?.map((item, index) => (
            <div className="item" key={index}>
              <div className="item-in">
                <figure>
                  <Image {...item.image} alt={item.title} />
                </figure>
                <div className="desc">
                  <div className="title">
                    <HTMLLimit content={item.title} maxLength={50} />
                  </div>
                  <div className="text">
                    <HTMLLimit content={item.desc} maxLength={200} />
                  </div>
                  <Anchor className="more" {...item.link}>
                    {app.settings.translations['read-more']}
                  </Anchor>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {data && data.length > 3 && (
          <button
            className="news-slide-arrow news-slide-arrow--next"
            onClick={() => slider.current?.slickNext()}
          >
            <Icon name="arrow-forward" />
          </button>
        )}
      </div>
    )
  }

  return (
    <div className="news section">
      <Container size={width >= 1550 ? 'wide' : 'medium'}>
        <Row xs={{ align: 'center' }}>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
            className="text-center"
          >
            <div className="section-title">{title}</div>
          </Column>
        </Row>
        <Row>{renderSlider()}</Row>
      </Container>
    </div>
  )
}

export default News
